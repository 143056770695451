.ant-switch:focus {
    outline: 0;
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
}

.ant-switch-checked:focus {
    box-shadow: 0 0 0 2px rgba(0, 116, 122, 0.2);
}

.ant-switch-checked {
    background-color: #00747a;
}

.ant-switch-handle::before {
    box-shadow: 0 2px 4px 0 rgba(0, 35, 11, 0.2);
}

.ant-switch-checked .ant-switch-loading-icon {
    color: #00747a;
}