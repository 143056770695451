.all-projects-page {
    .search-filter-form {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-top: 20px;
        margin-bottom: 5px;
        .ant-divider-vertical {
            height: 25px;
        }
        .ant-input-affix-wrapper {
            width: 370px;
        }
        .ant-dropdown-trigger {
            padding: 4px 10px;
        }
        .ant-select-clear {
            right: 55px;
        }
    }
    .ant-card {
        .ant-card-meta-title {
            font-size: 13px;
            font-weight: normal;
        }
        .project-card-steps {
            position: absolute;
            left: -1px;
            bottom: -1px;
            width: calc(100% + 2px);
            .project-card-step-item {
                &.selected {
                    background-color: #00747a;
                }
                &:first-child,
                &:last-child {
                    margin: 0;
                }
                margin: 0 2px;
                background-color: #c2c4c9;
                height: 2px;
            }
        }
        .hover-triger:hover + .hover-content,
        .hover-content:hover {
            display: block;
        }
        .hover-content {
            background-color: #fff;
            display: none;
            position: absolute;
            z-index: 1;
            top: 40px;
            left: 0;
            right: 0;
            margin-left: auto;
            margin-right: auto;
            .ant-descriptions-item-label {
                span {
                    font-size: 10px;
                }
            }
            .ant-descriptions-item-content {
                span {
                    font-size: 10px;
                }
            }
        }
        .whistlist-icon {
            position: absolute;
            right: 15px;
            top: 15px;
            font-size: 17px;
        }
    }
    .progress-legend {
        .title {
            font-weight: 500;
            font-size: 15px;
            line-height: 20px;
            color: #17171a;
            opacity: 0.45;
        }
        .legend-container {
            font-size: 15px;
            line-height: 20px;
            color: #17171a;
            // justify-content: flex-end;
            display: flex;
            .item {
                width: 62px;
                height: 3px;
                background-color: #c2c4c9;
            }
        }
    }
    .sort-btn {
        &:hover {
            svg {
                path {
                    fill: #00747a;
                }
            }
        }
        svg {
            width: 14px;
            path {
                fill: rgba(0, 0, 0, 0.85);
            }
        }
        &.ant-btn-primary {
            svg {
                path {
                    fill: #fff;
                }
            }
        }
    }
    .todo-container {
        .ant-space-item {
            margin-top: 10px;
            overflow: hidden;
        }
        .todo-input-container {
            padding: 12px 12px 0 12px;
            .ant-radio-wrapper {
                width: 100%;
                margin-top: 10px;
                .ant-input {
                    width: 100%;
                    padding-left: 0;
                    border-bottom: 1px solid #00747a;
                }
                & > span:nth-child(2) {
                    width: 100%;
                }
            }
        }
        .todo-list-container {
            width: 100%;
        }
        .ant-card-body {
            max-height: 816px;
            overflow: auto;
            padding: 12px;
        }
    }
}

.project-filter {
    background-color: #fff;
    width: 326px;
    padding: 20px;
    box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
}

.project-sort {
    background-color: #fff;
    width: 321px;
    padding: 10px 20px 20px 20px;
    box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    .sort-list {
        position: relative;
        z-index: 0;
        background-color: #f3f3f3;
        border-radius: 3px;
        outline: none;
        .sort-list-item {
            height: 53px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            padding: 16px 0;
            background-color: #fff;
            border-bottom: 1px solid #efefef;
            box-sizing: border-box;
            &:last-child {
                border-bottom: none;
            }
            .ant-radio {
                margin: 0 10px;
            }
            .ant-radio-button-wrapper {
                border: none;
                padding: 0;
                height: 13px;
                line-height: 13px;
            }
            .ant-checkbox + span {
                width: 180px;
            }
            .ant-radio-button-wrapper-checked {
                border: none;
            }
            .ant-radio-group {
                width: 15px;
            }
            .ant-radio-button-wrapper:not(:first-child)::before {
                content: none;
            }
            .ant-form-item {
                margin-bottom: 0;
            }
        }
    }
    .sort-label {
        height: 53px;
        padding: 16px 0;
        font-size: 15px;
        letter-spacing: 0.02em;
        color: #17171a;
    }
}

.sortableHelper {
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 16px 0;
    background-color: #fff;
    border-bottom: 2px solid #efefef;
    box-sizing: border-box;
    &:last-child {
        border-bottom: none;
    }
    .ant-checkbox {
        margin: 0 10px;
    }
    .ant-radio-button-wrapper {
        border: none;
        padding: 0;
        height: 13px;
        line-height: 13px;
    }
    .ant-checkbox + span {
        width: 180px;
    }
    .ant-radio-button-wrapper-checked {
        border: none;
    }
    .ant-radio-group {
        width: 15px;
    }
    .ant-radio-button-wrapper:not(:first-child)::before {
        content: none;
    }
    .ant-form-item {
        margin-bottom: 0;
    }
}

// Responsive
@media (min-width: 1600px) {
    .all-projects-page {
        .search-filter-form {
            margin-top: 0;
            margin-bottom: 0;
            justify-content: flex-end;
        }
        .ant-card {
            .hover-content {
                .ant-descriptions-item-label {
                    span {
                        font-size: 12px;
                    }
                }
                .ant-descriptions-item-content {
                    span {
                        font-size: 12px;
                    }
                }
            }
        }
    }
}
