// ANT-PICKER
.ant-picker:hover:not(.ant-picker.ant-picker-disabled),
.ant-picker-focused {
    border-color: $tealBlue;
}

.ant-picker-focused {
    box-shadow: 0 0 0 2px rgba(0, 116, 122, 0.2);
}

.ant-picker-range .ant-picker-active-bar {
    background: $tealBlue;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border: 1px solid $tealBlue;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border: 1px solid $tealBlue;
}

.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
    background: #a8bab8;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    color: #fff;
    background: $tealBlue;
}

.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
    background: #a8bab8;
}

.ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-end-near-hover::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-start-near-hover::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after {
    border-top: 1px dashed #00d5e0;
    border-bottom: 1px dashed #00d5e0;
}

.ant-picker-today-btn {
    color: $tealBlue;
}

.ant-picker-today-btn:hover {
    color: #168587;
}

.ant-picker-today-btn:active {
    color: #004d54;
}


.ant-picker-input > input[disabled] {
    color: rgba(0, 0, 0, 0.65);
}