.ant-input:hover {
  border-color: $tealBlue;
}

.ant-input:focus,
.ant-input-focused {
    border-color: #168587;
}

// AFFIX
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: $tealBlue;
}

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
    border-color: #168587;
}

.ant-input-affix-wrapper:hover {
    border-color: #168587;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: #168587;
}

//

// GROUP
.ant-input-group-addon .ant-select-open .ant-select-selector,
.ant-input-group-addon .ant-select-focused .ant-select-selector {
    color: #00747a;
}

// NUMBER
.ant-input-number:focus,
.ant-input-number-focused {
    border-color: #168587;
}

.ant-input-number:hover {
    border-color: #168587;
}
.ant-input-number-handler:hover .ant-input-number-handler-up-inner,
.ant-input-number-handler:hover .ant-input-number-handler-down-inner {
  color: #168587;
}

.ant-input-number-disabled:hover {
    border-color: #d9d9d9;
}




// SEARCH
.ant-input-search .ant-input:hover,
.ant-input-search .ant-input:focus {
    border-color: #168587;
}

.ant-input-search .ant-input:hover+.ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary),
.ant-input-search .ant-input:focus+.ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary) {
    border-left-color: #168587;
}

.ant-input-search-rtl > .ant-input-group > .ant-input-affix-wrapper:hover,
.ant-input-search-rtl > .ant-input-group > .ant-input-affix-wrapper-focused {
  border-right-color: #168587;
}
.ant-input-search-rtl .ant-input:hover + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary),
.ant-input-search-rtl .ant-input:focus + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary) {
  border-right-color: #168587;
}
.ant-input-search-rtl > .ant-input-group > .ant-input-affix-wrapper:hover,
.ant-input-search-rtl > .ant-input-group > .ant-input-affix-wrapper-focused {
  border-right-color: #168587;
}
