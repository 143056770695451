.utilize-card .ant-card-body {
  padding: 0;
}

.section-collapse {
  background: unset;

  .ant-collapse-header {
    padding: 0 !important;
  }

  .ant-collapse-content-box {
    padding: 0 !important;
  }
}

.ant-select-selection-item .btn-delete {
  display: none;
}
