$tealBlue: #00747a;
// Date Picker
@import "./config-component-style/ant-picker.scss";
// Radio Button
@import "./config-component-style/ant-radio.scss";
// Toggle Button
@import "./config-component-style/ant-switch.scss";
// Checkbox
@import "./config-component-style/ant-checkbox.scss";
// Input
@import "./config-component-style/ant-input.scss";
// Select
@import "./config-component-style/ant-select.scss";
// Tabs
@import "./config-component-style/ant-tab.scss";
// Button
@import "./config-component-style/ant-button.scss";
// Menu
@import "./config-component-style/ant-menu.scss";

.ant-spin-dot-item {
    background-color: #00747a;
}

.ant-spin-text {
    color: #00747a;
}
.text-primary {
    color: #00747a !important;
}
.highlight-menu-item {
    background-color: #00747a;
    color: #ffffff;
    &:hover {
        color: #ffffff !important;
    }
}