.form-item-wrapper {
  min-height: 2rem;
}

.form-builder {
  .control-action {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;

    &.control-left {
      right: calc(100% + 0.5rem);
    }

    &.control-right {
      left: calc(100% + 0.5rem);
    }
  }
}

.control-underline {
  & > .ant-select-selector {
    border-radius: 0 !important;
    border: 0 !important;
    border-bottom: 0.1rem solid #ccc !important;

    &:hover,
    &:focus {
      box-shadow: 0 0.1rem 0 0 #094977;
    }
  }
}

.form-builder-section {
  // padding: 0 4rem;
}

.close-button {
  position: absolute;
  right: 1.5rem;
  z-index: 1;
}

.outline-section {
  .ant-collapse-header {
    background-color: #fafbfc;
    padding-right: 0 !important;
    padding-left: 0 !important;
    border-bottom: none;
  }
  .ant-collapse-content-box {
    background-color: #fafbfc;
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}

.padding-predefined-card {
  .ant-card-body {
    padding: 0.75rem 1rem;
  }
}

.predefined-section-scroll {
  max-height: 650px;
  overflow-y: scroll;
  padding: 10px;
  margin: -10px;
}