@import "../../utils/colors.scss";

.viewAddClient {
  label.ant-form-item-required {
    position: relative;
  }

  .ant-form-item .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    position: absolute;
    right: -11px;
  }

  .viewTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .txtAddClient {
    // font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
  }

  .txtGeneralInformation {
    // font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;

    padding-top: 18px;
    padding-bottom: 16px;
  }

  .placeHolderUpload {
  // font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;

  text-align: center;

  /* Character / Secondary .45 */
  color: rgba(0, 0, 0, 0.45);
  }

  .txtLogoImage {
    .ant-form-item-label > label {
        // font-family: "Roboto";
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 22px;
        /* identical to box height, or 157% */

        /* Character/Primary .85 */

        color: rgba(0, 0, 0, 0.85);
    }
  }

  .ant-form-item-label > label {
    // font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;

    padding: 0px;
  }

  .ant-select-multiple .ant-select-selection-item-content {
    //   font-family: "Roboto";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
  }

  .txtInput {
    //   font-family: "Roboto";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 22px;
    
    box-sizing: border-box;

    display: flex;
    flex-direction: row;
    align-items: center;

    /* Neutral/1 */
    background: $white;
    /* Neutral/4-stroke */
    border: 1px solid $gainsboro;
    border-radius: 4px;
  }
  
  .ant-tooltip {
    display: none;
  }

  .ant-upload-list-picture-card .ant-upload-list-item-error {
    border-color: $gainsboro;
  }

  .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye {
    display: none;
  }
}
