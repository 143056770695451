.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus+.ant-checkbox-inner {
    border-color: $tealBlue;
}

.ant-checkbox-checked::after {
    border: 1px solid $tealBlue;
}

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: $tealBlue;
    border-color: $tealBlue;
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: $tealBlue;
}