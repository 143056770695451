@import "../../utils/colors.scss";

.alert-component {
  position: fixed;
  top: 22px;
  right: 20px;
  border: 1px solid rgba(82, 196, 26, 1);
  z-index: 100;
  border-radius: 8px;
  padding: 16px 16px 16px 24px;
  font-size: 16px;
  font-weight: 400;
  background-color: $palegreen;
  
  .ant-alert-message {
    margin-right: 18px;
  }
}
