.probability-item {
  .ant-collapse-header {
    float: right;
    margin-top: -60px;
    padding-right: 0 !important;
    .ant-collapse-arrow {
      margin-right: 0 !important;
    }
  }
  .ant-collapse-content-box {
    padding: 0 0 10px 0 !important;
  }
  &.hidden {
    display: none;
  }
  & .ant-space-align-baseline {
    & .ant-space-item {
      &:first-child {
        width: 300px;
        & .ant-input-number {
          width: 100%;
        }
      }
    }
  }
  & .down-collapse-button,
  & .up-collapse-button {
    border: none;
    outline: none;
    background-color: transparent;

    &:disabled {
      color: #929394;
      cursor: not-allowed;
    }
  }
}
