@import "../../utils/colors.scss";

.viewSort {
  .ant-radio-checked .ant-radio-inner {
    border-color: $tealBlue;
  }

  .ant-radio-inner::after {
    background-color: $tealBlue;
  }

  .btnSort {
    @include styleButton;

    .txtSort {
      @include styleTextButton;
    };
  }

  .btnReset {
    @include styleButton;

    background-color: $white;
    border: 1px solid $gainsboro;

    &:hover {
      background-color: $white;
      border-color: $tealBlue;
    }

    .txtReset {
      @include styleTextButton;
      color: $charcoal;

      &:hover {
        color: $tealBlue;
      }
    }
  }

  .btn-sort-list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 8px;
    background-color: #fff;
    border-bottom: 1px solid #efefef;
    box-sizing: border-box;
  
    &:last-child {
      border-bottom: none;
    }
  
    .ant-radio {
      margin: 0 10px;
    }
  
    .ant-radio-button-wrapper {
      border: none;
      padding: 0;
      height: 13px;
      line-height: 13px;
    }
  
    .ant-checkbox+span {
      width: 180px;
    }
  
    .ant-radio-button-wrapper-checked {
      border: none;
    }
  
    .ant-radio-group {
      width: 15px;
    }
  
    .ant-radio-button-wrapper:not(:first-child)::before {
      content: none;
    }
  
    .ant-form-item {
      margin-bottom: 0;
    }
  }
}
