@import "../../utils/colors.scss";

.viewAddClientType {
  label.ant-form-item-required {
    position: relative;
  }

  .ant-form-item .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    position: absolute;
    right: -11px;
  }

  .viewGeneralInformation {
    background-color: $white;
    border-radius: 4px;
    padding-top: 24px;
    padding-bottom: 24px;

    .txtInput {
      //   font-family: "Roboto";
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 22px;
      
      box-sizing: border-box;

      display: flex;
      flex-direction: row;
      align-items: center;

      /* Neutral/1 */
      background: $white;
      /* Neutral/4-stroke */
      border: 1px solid $gainsboro;
      border-radius: 4px;
    }
  }
}
