@import "../../utils/colors.scss";

.popupSelected {
  display: flex;
  flex-direction: column;
  padding: 8px;

  .txtSearchName {
    /* input */
    box-sizing: border-box;

    /* Neutral/1 */
    background: $white;
    /* Neutral/4-stroke */

    border: 1px solid $gainsboro;
    border-radius: 4px;
  }

  .containerCheckBox {
    display: flex;
    flex-direction: column;
    padding-top: 8px;
  
    .ant-select-item {
      padding: 5px;
    }
  
    .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
      background-color: $paleCyan;
    }
  }
  
  .btnAddNew {
    // font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
  
    /* DayBreak Blue/6 */
    color: #00747a !important;
    text-align: left;
    margin-top: 5px;
  }

  .btnAddNew:hover{
    background: #f5f5f5;
  }
}

