.ant-button {
    border-radius: 4px;
}

.ant-btn-primary {
    border-color: #00747a !important;
    background: #00747a !important;
    border-radius: 4px !important;
}

.ant-btn:hover,
.ant-btn:focus {
    color: #168587 !important;
    border-color: #168587 !important;
    background: #fff;
}

.ant-btn:active {
    color: #004d54;
    border-color: #004d54;
}

.ant-btn:hover,
.ant-btn:focus,
.ant-btn:active {
    text-decoration: none;
    background: #fff;
}

.ant-btn[disabled],
.ant-btn[disabled]:hover,
.ant-btn[disabled]:focus,
.ant-btn[disabled]:active {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9 !important;
    background: #f5f5f5 !important;
}

.ant-btn-primary {
    color: #fff !important;
    border-color: #00747a !important;
    background: #00747a !important;
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
    color: #fff !important;
    border-color: #168587 !important;
    background: #168587 !important;
}

.ant-btn-primary:active {
    color: #fff !important;
    border-color: #004d54 !important;
    background: #004d54 !important;
}

.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:hover,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary[disabled]:active {
    color: rgba(0, 0, 0, 0.25) !important;
    border-color: #d9d9d9 !important;
    background: #f5f5f5 !important;
}

.ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child) {
    border-right-color: #168587 !important;
    border-left-color: #168587 !important;
}

.ant-btn-group .ant-btn-primary:first-child:not(:last-child) {
    border-right-color: #168587 !important;
}

.ant-btn-group .ant-btn-primary:last-child:not(:first-child),
.ant-btn-group .ant-btn-primary+.ant-btn-primary {
    border-left-color: #168587 !important;
}


.ant-btn-ghost:hover,
.ant-btn-ghost:focus,
.ant-btn-link:hover,
.ant-btn-link:focus,
.ant-btn-text:hover,
.ant-btn-text:focus {
    color: #168587 !important;
    border-color: #168587 !important;
    background: transparent;
}

.ant-btn-ghost:active,
.ant-btn-link:active {
    color: #004d54;
    border-color: #004d54;
    background: transparent;
}


.ant-btn-dashed:hover,
.ant-btn-dashed:focus {
    color: #168587 !important;
    border-color: #168587 !important;
    background: #fff !important;
}

.ant-btn-dashed:active {
    color: #004d54 !important;
    border-color: #004d54 !important;
    background: #fff !important;
}

.ant-btn-dangerous:hover,
.ant-btn-dangerous:focus {
    color: #ff7875 !important;
    border-color: #ff7875 !important;
    background: #fff !important;
}

.ant-btn-dangerous:active {
    color: #d9363e !important;
    border-color: #d9363e !important;
    background: #fff !important;
}

.ant-btn-dangerous.ant-btn-primary {
    color: #fff !important;
    border-color: #ff4d4f !important;
    background: #ff4d4f !important;
}

.ant-btn-dangerous.ant-btn-primary:hover,
.ant-btn-dangerous.ant-btn-primary:focus {
    color: #fff !important;
    border-color: #ff7875 !important;
    background: #ff7875 !important;
}

.ant-btn-dangerous.ant-btn-primary:active {
    color: #fff;
    border-color: #d9363e;
    background: #d9363e;
}

.ant-btn-link {
    color: #00747a !important;
}


.ant-btn-text:hover,
.ant-btn-text:focus {
    color: #168587 !important;
    border-color: transparent !important;
    background: transparent;
}

.ant-btn-text:active {
    color: #004d54 !important;
    border-color: transparent !important;
    background: transparent;
}


.ant-btn-group-rtl.ant-btn-group .ant-btn-primary:last-child:not(:first-child),
.ant-btn-group-rtl.ant-btn-group .ant-btn-primary+.ant-btn-primary {
    border-right-color: #168587 !important;
}

.ant-btn-group-rtl.ant-btn-group .ant-btn-primary:last-child:not(:first-child)[disabled],
.ant-btn-group-rtl.ant-btn-group .ant-btn-primary+.ant-btn-primary[disabled] {
    border-left-color: #168587 !important;
}