$white: #ffffff;
$gainsboro: #e6e8ec;
$tealBlue: #00747a;
$darkTurquoise: #548b91;
$deepJungleGreen: #3b7b82;
$charcoal: #3c3c3c;
$paleCyan: #e6f7ff;
$dayBreak: #1890ff;
$palegreen: #f6ffed;

@mixin styleButton {
  box-sizing: border-box;
  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 16px;

  background: $tealBlue;

  border: 1px solid $tealBlue;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
  border-radius: 4px;
  text-transform: capitalize;

  &:hover {
    background: $darkTurquoise;
  }

  &:focus {
    background: $darkTurquoise;
  }
}

@mixin styleTextButton {
  // font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */
  text-align: center;

  /* White FFFFF */
  color: $white;
}

@mixin button {
  box-sizing: border-box;

  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 32px;
  height: 32px;

  /* Neutral/1 */
  background: $white;
  /* Neutral/4-stroke */

  border: 1px solid $gainsboro;
  /* drop-shadow/button-secondary */

  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
  border-radius: 4px;

  &:hover {
    border-color: $tealBlue;
    color: $tealBlue;
  }
}

.okText {
  background: $tealBlue;

  border: 1px solid $tealBlue;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
  border-radius: 4px;
  text-transform: capitalize;

  &:hover {
    background: $darkTurquoise;
    border: 1px solid $tealBlue;
  }

  &:focus {
    background: $darkTurquoise;
    border: 1px solid $tealBlue;
  }
}

.cancelText {
  background-color: $white;
  border: 1px solid $gainsboro;

  &:hover {
    background-color: $white;
    border-color: $tealBlue;
  }

  span {
    color: $charcoal;

    &:hover {
      color: $tealBlue;
    }
  }
}

.lblTable {
  // font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;

  /* Primary/07 */
  color: $deepJungleGreen;

  text-decoration: none;
}

.selectControl {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
}