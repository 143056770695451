.general-info-label {
  // font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;

  /* Character/Title .85 */
  color: rgba(0, 0, 0, 0.85);

  .general-info-value {
    // font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 24px;

    /* Character/Primary .85 */
    color: rgba(0, 0, 0, 0.85);

    word-wrap: break-word;
    white-space: pre-line;
  }

  .general-info-Link {
    // font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 24px;
    text-decoration: none;

    word-wrap: break-word;
    white-space: pre-line;
  }
}