.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    color: rgba(0, 0, 0, 0.65);
}


.ant-input-group-addon .ant-select-open .ant-select-selector,
.ant-input-group-addon .ant-select-focused .ant-select-selector {
    color: #00747a;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: #168587;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: #168587;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state {
    color: #00747a;
}

.ant-select-tree-checkbox-wrapper:hover .ant-select-tree-checkbox-inner,
.ant-select-tree-checkbox:hover .ant-select-tree-checkbox-inner,
.ant-select-tree-checkbox-input:focus+.ant-select-tree-checkbox-inner {
    border-color: #00747a;
}

.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner {
    background-color: #00747a;
    border-color: #00747a;
}

.ant-select-tree-focused:not(:hover):not(.ant-select-tree-active-focused) {
    background: #a8bab8;
}

.ant-select-tree .ant-select-tree-node-content-wrapper.ant-select-tree-node-selected {
    background-color: #6fada8;
}

.ant-select-tree-node-content-wrapper[draggable='true'] .ant-tree-drop-indicator {
    background-color: #00747a;
}

.ant-select-tree-node-content-wrapper[draggable='true'] .ant-tree-drop-indicator::after {
    border: 2px solid #00747a;
}

.ant-select-tree .ant-select-tree-treenode.drop-container>[draggable] {
    box-shadow: 0 0 0 2px #00747a;
}