@import "../../utils/colors.scss";

.comboBox {
  display: flex;
  flex-direction: column;
  padding: 0px;

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 4px;

    &:hover {
      border-color: $tealBlue;
    }
  }

  .ant-select-selection-placeholder {
    // font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;

    /* Character/Title .85 */
    color: rgba(0, 0, 0, 0.85);
  }

  label {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    padding-bottom: 8px;

    /* identical to box height, or 157% */
    text-transform: capitalize;

    /* Character/Title .85 */
    color: rgba(0, 0, 0, 0.85);
  }

  .ant-select .ant-select-clear {
    opacity: 1;
  }
}
