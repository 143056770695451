$tealBlue: #00747a;
$paleBlue: #e6f8fa;
$white: #ffffff;

.ant-layout-sider-collapsed + .site-layout {
  margin-left: 80px !important;
}

.ant-breadcrumb > span:last-child a {
  color: $tealBlue !important;
}

.ant-breadcrumb > span a {
  color: black !important;
}

.tophat-sider {
  &.ant-layout-sider-collapsed {
    .logo {
      .site-logo-text {
        display: none;
      }
    }
  }

  .logo {
    margin: 18px;
    color: green;
    text-align: center;

    .site-logo-text {
      margin-top: 7px;
      margin-left: 13px;
    }
  }
  // MENU FOR DESKTOP
  .ant-menu-inline {
    border-right: none;
  }

  .ant-menu:not(.ant-menu-submenu-selected) .ant-menu-item-selected {
    background-color: #00747a;
    color: $white;

    &:hover {
      color: $white !important;
    }
  }

  .ant-menu-submenu-selected {
    background-color: $tealBlue;
    color: $white;

    & > .ant-menu-submenu-title:hover {
      color: $white;
    }

    .ant-menu-submenu-arrow::before,
    .ant-menu-submenu-arrow::after {
      background: $white;
    }

    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
      color: $tealBlue;
      background-color: $paleBlue !important;

      &:hover {
        color: $tealBlue !important;
      }
    }
  }
  // MENU FOR VERTICAL MENU
  .ant-menu-vertical .ant-menu-submenu-selected {
    color: $white;
  }
  // SIDER
  .ant-layout-sider-children {
    overflow: hidden auto !important;
  }
}

.ant-layout-content {
  // margin-top: 60px;
  // overflow: scroll;
  height: calc(100vh - 61px);
}

.container-layout {
  display: flex;

  .ant-layout-content {
    margin-top: 60px;
    overflow: scroll;
    height: 100%;
  }
}

.site-layout {
  margin-left: 240px;

  // for antd Drawer
  .site-drawer-render-in-current-wrapper {
    position: relative;
  }

  .site-main {
    background-color: #fff;
    border-left: 1px solid #e1e4e5;
    padding: 24px;
    background-color: #fafbfc;
    // height: calc(100vh - 61px);
    overflow-y: auto;
    max-height: calc(100vh - 61px);
    height: 100%;
    position: relative;

    .page-header {
      text-transform: capitalize;
      font-weight: bold;
      font-size: 18px;
      letter-spacing: 0.02em;
      margin-bottom: 10px;
    }
  }

  .site-main-not-table {
    background-color: #fff;
    border-left: 1px solid #e1e4e5;
    padding: 20px 25px;
    background-color: #fafbfc;
    max-height: 100%;
    height: 100%;

    .page-header {
      text-transform: capitalize;
      font-weight: bold;
      font-size: 18px;
      letter-spacing: 0.02em;
      margin-bottom: 10px;
    }
  }

  .site-header-container {
    padding: 16px 24px;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-decoration: none;

    .ant-dropdown-link {
      margin-left: 10px;
      text-decoration: none;
    }

    .ant-breadcrumb {
      .ant-breadcrumb-link {
        text-transform: capitalize !important;
        text-decoration: none;
      }
    }

    .ant-dropdown-trigger {
      color: #17171a;
    }
    .site-header-left {
    }

    .site-header-right {
      width: fit-content;
    }
  }
  // .site-header {
  //   padding: 17px 25px;
  //   border-bottom: 1px solid #e1e4e5;
  //   display: flex;
  //   justify-content: space-between;
  //   position: fixed;
  //   width: calc(100vw - 240px);

  //   .site-header-action {
  //     .ant-divider {
  //       margin: 0 15px;
  //     }

  //     .ant-dropdown-link {
  //       margin-left: 10px;
  //       text-decoration: none;
  //     }

  //     .ant-dropdown-trigger {
  //       color: #17171a;

  //       .anticon {
  //         margin-left: 5px;
  //       }
  //     }
  //   }

  //   .ant-breadcrumb {
  //     .ant-breadcrumb-link {
  //       text-transform: capitalize !important;
  //       text-decoration: none;
  //     }

  //     .page-header {
  //       text-transform: capitalize;
  //       font-weight: bold;
  //       font-size: 18px;
  //       letter-spacing: 0.02em;
  //       margin-bottom: 10px;
  //     }
  //   }

  //   .site-main-not-table {
  //     background-color: #fff;
  //     border-left: 1px solid #e1e4e5;
  //     padding: 20px 25px;
  //     background-color: #fafbfc;
  //     max-height: 100%;
  //     height: 100%;

  //     .page-header {
  //       text-transform: capitalize;
  //       font-weight: bold;
  //       font-size: 18px;
  //       letter-spacing: 0.02em;
  //       margin-bottom: 10px;
  //     }
  //   }

  //   .site-header {
  //     padding: 17px 25px;
  //     border-bottom: 1px solid #e1e4e5;
  //     display: flex;
  //     justify-content: space-between;
  //     position: fixed;
  //     width: calc(100vw - 240px) !important;

  //     .site-header-action {
  //       .ant-divider {
  //         margin: 0 15px;
  //       }

  //       .ant-dropdown-link {
  //         margin-left: 10px;
  //         text-decoration: none;
  //       }

  //       .ant-dropdown-trigger {
  //         color: #17171a;

  //         .anticon {
  //           margin-left: 5px;
  //         }
  //       }
  //     }

  //     .ant-breadcrumb {
  //       .ant-breadcrumb-link {
  //         text-transform: capitalize !important;
  //         text-decoration: none;
  //       }
  //     }
  //   }
  // }
}

.site-header-user-menu {
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  width: 320px;
  height: 196px;
  background: #ffffff;
  box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.1);
  border-radius: 4px;

  .user-menu-name {
    margin: 5px 0;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0.02em;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #17171a;
    text-align: center;
  }

  .user-menu-email {
    font-weight: 500;
    font-size: 12px;
    line-height: 130%;
    letter-spacing: 0.02em;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #83898a;
    text-align: center;
  }

  .user-menu-devider {
    margin: 16px 0;
  }
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 48px 68px;
  background: #ffffff;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.12), 0px 15px 35px rgba(60, 66, 87, 0.08);
  border-radius: 4px;
}

.anticon {
  img,
  svg {
    vertical-align: inherit;
  }
}

.custom-clear-autocomplete {
  .ant-input {
    padding-right: 22px;
  }

  .ant-select-clear {
    display: none;
  }

  .custom-clear-btn {
    position: absolute;
    right: 53px;
    top: 9px;
    color: rgba(0, 0, 0, 0.25);
    z-index: 1;
  }
}

.custom-clear-select-btn {
  position: absolute;
  color: rgba(0, 0, 0, 0.25);
}

.download-loading {
  width: 350px;
  background-color: #fafbfc;

  z-index: 9999;
  position: fixed;
  bottom: 0;
  right: 0;

  .download-header {
    background-color: #17171a;
    color: #fafbfc;
    padding: 10px 20px;
  }

  .download-body {
    background-color: #fafbfc;
    border: 1px solid #cccccc;
    color: #17171a;
    padding: 10px 20px;

    .download-file-list {
      margin-bottom: 10px;

      .anticon-cloud-download {
        margin-right: 10px;
      }

      display: flex;
      justify-content: space-between;
      align-items: center;
      color: grey;
    }

    .download-file-list:last-child {
      margin-bottom: 0;
    }
  }
}

.ant-alert-close-icon .anticon-close {
  color: #52c41a;
}

.ant-modal-footer {
  border-top: none;
}

.ant-collapse-content {
  border-top: none;
}
.ant-steps-item-container {
  cursor: pointer;
}

*::-webkit-scrollbar {
  width: 0.75rem;
  height: 1rem;
}

*::-webkit-scrollbar-track {
  background-color: transparent;
}

*::-webkit-scrollbar-track-piece {
  background-color: transparent;
}

*::-webkit-scrollbar-thumb {
  border-radius: 0.375rem;
  background-color: #cad1c9;
  background-clip: padding-box;
  border: 0.25rem solid transparent;
}

*::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  visibility: hidden;
}

*::-webkit-scrollbar-corner {
  background-color: transparent;
}
.ant-spin-container {
  position: static;
  height: 100%;
}
.ant-spin-nested-loading {
  position: static;
}
